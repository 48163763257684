.smr-topbar {
    height: 50px;
    background-color: rgb(182, 182, 182);
    border-radius: 5px 5px 0px 0px;
}

.smr-topbar h4 {
    line-height: 50px;
}


.smr-content {
    background-color: #fff;
}